<template>
  <div class="centered text-center"
       style="border: 1px solid #88b462 ; border-radius: 45px; background-color: #262424;padding: 20px;margin: 10px;">
    <img v-lazy="zamnaImg" height="60px">
    <p style="margin-top: -10px;font-weight: normal;font-size: 11px">TULUM OFFICIAL SELLERS</p>
    <hr>
    <p>
      We provide multiple <strong>secure payment options</strong> & <strong>support</strong> during your planning & execution phase.
    </p>
    <br>

    <img v-lazy="sslSecure" alt="secure transaction">
    <br>
    <div style="background-color: #262424">
      <br>
      <img v-lazy="masterCard" height="20px" alt="mastercard">
      <img v-lazy="paypal" height="20px" alt="paypal">
    </div>

  </div>
</template>
<script>
export default {
  name: 'OfficialSellerCard',
  data: () => {
    return {
      primaryColor: '#88b462',
      sslSecure: 'https://imgix.cosmicjs.com/e1f6e920-5504-11ef-b1ea-f56c65dfade9-sslsecurecolor_color.svg',
      masterCard: 'https://imgix.cosmicjs.com/6929cfc0-5505-11ef-b1ea-f56c65dfade9-mastercard_color.svg',
      paypal: 'https://imgix.cosmicjs.com/9c847aa0-5505-11ef-b1ea-f56c65dfade9-paypal.svg',
      zamnaImg: 'https://imgix.cosmicjs.com/5246c9a0-5520-11ef-b1ea-f56c65dfade9-zamna-festival-logo.png'
    }
  }
}
</script>
